import React from "react";
import theme from "theme";
import { Theme, Text, Section, Box, Image, LinkBox } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Автосервіс Motor Group
			</title>
			<meta name={"description"} content={"Motor Group - професійний автосервіс, який пропонує широкий спектр послуг з обслуговування та ремонту автомобілів. Якість, швидкість та доступні ціни."} />
			<meta property={"og:title"} content={"Автосервіс Motor Group"} />
			<meta property={"og:description"} content={"Motor Group - професійний автосервіс, який пропонує широкий спектр послуг з обслуговування та ремонту автомобілів. Якість, швидкість та доступні ціни."} />
			<meta property={"og:image"} content={"https://topmalibu-ua.org/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://topmalibu-ua.org/img/1967833.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://topmalibu-ua.org/img/1967833.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://topmalibu-ua.org/img/1967833.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://topmalibu-ua.org/img/1967833.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://topmalibu-ua.org/img/1967833.png"} />
			<meta name={"msapplication-TileImage"} content={"https://topmalibu-ua.org/img/1967833.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text font="--headline1" md-font="--headline2" margin="20px 0 0 0">
			Наші Послуги
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
			У Motor Group ми пропонуємо повний комплекс послуг для вашого автомобіля. Наші досвідчені фахівці використовують лише найкращі матеріали та сучасне обладнання для забезпечення високої якості обслуговування. Довірте свій автомобіль професіоналам!
			</Text>
		</Section>
		<Section padding="20px 0 80px 0" lg-padding="60px 0 60px 0" md-padding="40px 0 40px 0" sm-padding="30px 0 30px 0">
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" sm-width="100%" lg-padding="0px 0px 0px 0px">
					
						<Text
						font="--headline2"
						color="--dark"
						text-align="center"
						md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
						margin="16px 0px 50px 0px"
						>
							Ремонт двигуна
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
						Ми спеціалізуємося на ремонті двигунів будь-якої складності. Від капітального ремонту до заміни окремих деталей - наші спеціалісти швидко та якісно виконають всі роботи.
						</Text>
					</Box>
				</Box>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://topmalibu-ua.org/img/3.jpg"
						border-radius="24px"
						max-width="100%"
						width="100%"
						object-fit="cover"
					/>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://topmalibu-ua.org/img/4.jpg"
						border-radius="24px"
						max-width="100%"
						width="100%"
						object-fit="cover"
					/>
				</Box>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" sm-width="100%" lg-padding="0px 0px 0px 0px">
						
					<Text
						font="--headline2"
						color="--dark"
						text-align="center"
						md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
						margin="16px 0px 50px 0px"
						>
							Технічний огляд
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
						Регулярний технічний огляд допоможе підтримувати ваш автомобіль у відмінному стані. Ми перевіримо всі системи та вузли, щоб виявити та усунути будь-які несправності на ранніх стадіях.
						</Text>
					</Box>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" sm-width="100%" lg-padding="0px 0px 0px 0px">
						
					<Text
						font="--headline2"
						color="--dark"
						text-align="center"
						md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
						margin="16px 0px 50px 0px"
						>
							Ремонт підвіски
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
						Проблеми з підвіскою можуть суттєво вплинути на комфорт та безпеку водіння. Наші спеціалісти швидко виявлять та виправлять всі несправності, забезпечуючи плавну та комфортну їзду.
						</Text>
					</Box>
				</Box>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://topmalibu-ua.org/img/5.jpg"
						border-radius="24px"
						max-width="100%"
						width="100%"
						object-fit="cover"
					/>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://topmalibu-ua.org/img/6.jpg"
						border-radius="24px"
						max-width="100%"
						width="100%"
						object-fit="cover"
					/>
				</Box>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" sm-width="100%" lg-padding="0px 0px 0px 0px">
						
					<Text
						font="--headline2"
						color="--dark"
						text-align="center"
						md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
						margin="16px 0px 50px 0px"
						>
							Шиномонтаж та балансування
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
						Правильний монтаж та балансування шин забезпечують безпечне та комфортне водіння. Ми використовуємо сучасне обладнання для гарантії точності та якості робіт.
						</Text>
					</Box>
				</Box>
			</LinkBox>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					sm-font="--headline3"
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					md-text-align="left"
				>
					Забезпечте довговічність вашого автомобіля
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					Замовте професійні послуги автосервісу Motor Group!
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						position="absolute"
						width="100%"
						top="auto"
						left={0}
						src="https://topmalibu-ua.org/img/7.jpg"
						object-fit="cover"
						display="block"
						right={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});